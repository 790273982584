

const Content = () => {

    return(
        <div className="flex " id="about">
            <div className="grid justify-center grid-cols-1 sm:grid-cols-2">
                <div className="col-span-1 my-10 bg-slate-50">
                    <h1 className="flex justify-center font-bold text-2xl text-lime-600 m-3 underline">Our Mission</h1>
                    <p className="mx-12 p-2">
                        <span className="font-semibold text-lime-700">At Anfinite Bioenergy, we have a singular mission: </span>
                        to pioneer eco-friendly 
                        fuel solutions that not only reduce carbon footprints
                        but also foster a cleaner and healthier planet.
                    </p>
                </div>
                <div className="col-span-1 my-10 bg-slate-50">
                    <h1 className="flex justify-center font-bold text-2xl text-lime-600 m-3 underline">The Power of Bio-Ethanol</h1>
                    <p className="mx-12 p-2">
                        Discover the power of bio-ethanol – a renewable energy source derived from organic materials. Our cutting-edge technology ensures the production of clean, green fuel that helps mitigate environmental impact without compromising performance.
                    </p>
                </div>

                <div className="col-span-1 my-10 bg-slate-50">
                    <h1 className="flex justify-center font-bold text-2xl text-lime-600 m-3 underline">Innovation in Energy</h1>
                    <p className="mx-12 p-2">
                    Stay ahead with our innovative approach to energy solutions. Join us as we explore new horizons and set the standard for a sustainable future.
                    </p>
                </div>
                <div className="col-span-1 my-10 bg-slate-50">
                <h1 className="flex justify-center font-bold text-2xl text-lime-600 m-3 underline">Join the Movement</h1>
                    <p className="mx-12 font-sans p-2">
                    Embark on a journey with us towards a greener and healthier future. Whether you're an individual, a business, or a community, your choice to embrace bio-ethanol contributes to a collective effort in building a more sustainable world.
                    </p>
                </div>
            </div>
        </div>
    )

}
export default Content;