// Footer.js
import React from 'react';

const FooterComponent = () => {
  return (
    <footer className="bg-lime-500 text-white p-6">
      <div className="container mx-auto flex items-center justify-between">
        <div className="left-4">
          <p>All rights reserved</p>
        </div>
        <div className="right-4">
          <p>@ 2024, Copyright Anfinite</p>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;