import React from "react";

const Header = () => {
  return (
    <>
    <div className="mx-auto bg-white id=home">
      <nav className="grid grid-cols-1 sm:grid-cols-2">
      <div className="mx-auto sm:mx-0 sm:ml-8">
        <img src="logo.png" alt="Company Logo" className="h-28 w-48"/>
      </div>
      <div className="flex justify-center h-14 sm:h-28">
        <ul className="flex">
          <li className="text-lg text-black font-semibold hover:text-lime-600 px-5 flex items-center"><a href="#home">Home</a></li>
          <li className="text-lg text-black font-semibold hover:text-lime-600 px-5 flex items-center"><a href="#about">About</a></li>
          <li className="text-lg text-black font-semibold hover:text-lime-600 px-5 flex items-center"><a href="#contact">Contact</a></li>
        </ul>
      </div>
      </nav>
    </div>
    </>
  );
};

export default Header;
