import Header from "./Header";
import MainSection from "./MainSection";
import ContactSection from "./ContactSection";
import React from 'react';
import Carousel from "./Carousel";
import Content from './Content';
import FooterComponent from "./FooterComponent";

export default function App() {
  let slides = [
   "image_1.jpg",
   "image_2.jpg"
  ];
  return (
    <>
    <div className="sticky top-0 z-10">
      <Header/>
     </div> 
      <MainSection />
      <div className="w-full mx-auto">
      <Carousel slides={slides} />
      </div>
      <Content/>
      <ContactSection />
      <FooterComponent />
    </>
  );
}
