import React from "react";

const MainSection  = () => {
  return (
    <section className="my-6">
      <div className="mt-5">
        <div className="flex flex-col items-center sm:flex-row sm:justify-center">
          <h1 className="text-4xl font-bold bg-lime-600 text-white pl-4 py-2 pr-4 sm:pr-0">ANFINITE</h1>
          <h1 className="text-4xl font-bold bg-white text-lime-600 py-2">BIOENERGY</h1>
        </div>
      
        <div className="flex justify-center">
        <p className="text-xl m-2">Towards a <span className="text-lime-600 font-bold">Greener</span> Future.</p>
        </div>
      </div>
      <div className="mx-auto mt-10">

       <div className="flex flex-col items-center sm:flex-row sm:justify-center mb-4">
          <h2 className="text-3xl font-bold flex justify-center ">Welcome to</h2>
          <h2 className="text-3xl font-bold flex justify-center text-lime-600 ml-2">Our Green World</h2>
        </div>      

        <ul className="mx-10">
          <li className="flex justify-center">
            At anfinite bio energy we are dedicated to providing sustainable and clean fuel solutions
            through bio-ethanol. Join us on our journey towards a greener and
            healthier future.
          </li>
          <li className="flex justify-center"> 
            We are at the forefront of a sustainable revolution, committed to transforming the energy landscape through bio-ethanol. 
          </li>
        </ul>
      </div>
    </section>
  );
};

export default MainSection;
