import React from "react";

const ContactSection = () => {
  const companyEmail = "anfinite.bio@gmail.com";

  return (
    <section className="mt-10 mb-4 flex">
      <div className="mx-12">
        <h2 className="text-2xl font-bold mb-3 text-lime-900" id="contact">Contact Us</h2>
        <p className="font-semibold text-md text-gray-800">ANFINITE BIOENERGY PRIVATE LIMITED<br/>
           B-403, SHUBH CITY, NEW RTO RD, PALDA NAKA,<br/>
           Indore, Madhya Pradesh, India, 452020</p><br/>
        <p className="text-lg">
          For any inquiries or partnership opportunities, please reach out to us
          at: 
          <a href={`mailto:${companyEmail}`} className="text-lime-700 font-semibold pl-1">
            {" "}
            {companyEmail}
          </a>
        </p>
      </div>
    </section>
  );
};

export default ContactSection;
